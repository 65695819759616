import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: '@home/home.module#HomePageModule' },
  {
    path: 'support',
    loadChildren: '@support/support.module#SupportPageModule'
  },
  {
    path: 'spotlight',
    loadChildren: '@spotlight/spotlight.module#SpotlightPageModule'
  },
  { path: 'login', loadChildren: '@auth/login/login.module#LoginPageModule' },
  {
    path: 'register',
    loadChildren: '@auth/register/register.module#RegisterPageModule'
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
